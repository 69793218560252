import { useCallback } from "react";
import {
  callEmailReaderAPI,
  delay,
  executeCurlCommand,
  fetchPlus,
  getCoordinatesForDevice,
  getPropByString,
  invertDirection,
  trackExceptionOnFirestore,
  trackSentryException,
  wasAudioPlayingInTheLastThreeSeconds,
} from "../utils/helpers";
import { useAtomValue } from "jotai";
import { useLoaderData } from "react-router-dom";
import {
  BuildData,
  CompanyData,
  ImageDimensions,
  SettingsData,
  TestSuiteLoaderApp,
} from "../utils/types";
import { FASTAPI_SERVER_URL } from "../constants/aws-constants";

const useCommandHandler = ({
  session,
  gptStepsRef,
  audioFramesRef,
  buildData,
  settingsData,
}: {
  session: any;
  gptStepsRef: any;
  audioFramesRef: any;
  buildData: BuildData;
  settingsData: SettingsData;
}) => {
  const handleExecuteCommand = async (callback: () => Promise<any>) => {
    try {
      await callback();
    } catch (e) {
      trackSentryException(e);
      await trackExceptionOnFirestore({ error: e, source: "appetize" });
      throw e;
    }
  };

  const handleSetLocation = useCallback(
    async (cmd: string) => {
      const regexMatch = [
        ...cmd.matchAll(
          /lat=([+-]?[0-9]*[.]?[0-9]+).?\s?long=([+-]?[0-9]*[.]?[0-9]+)/g
        ),
      ]?.[0];

      if (regexMatch) {
        const [whole, latitude, longitude] = regexMatch;

        await handleExecuteCommand(() =>
          session.current.data.setLocation(Number(latitude), Number(longitude))
        );
        console.log(`Changing location to ${latitude}, ${longitude}`);
      } else {
        console.log("Failed to extract coordinates from command: ", cmd);
      }
    },
    [session.current]
  );

  const handleTabOn = useCallback(
    async (cmd: string, imageDimensions: ImageDimensions) => {
      const coordinates = getCoordinatesForDevice(
        session.current?.data?.device,
        imageDimensions,
        cmd
      );

      if (coordinates !== null) {
        const { deviceX, deviceY } = coordinates;
        console.log(`tapping at x=${deviceX} y=${deviceY}`);
        await handleExecuteCommand(() =>
          session.current.data.tap({
            coordinates: { x: deviceX, y: deviceY },
            duration: 200,
          })
        );
      }
    },
    [session.current]
  );

  const handleLongPress = useCallback(
    async (cmd: string, imageDimensions: ImageDimensions) => {
      const coordinates = getCoordinatesForDevice(
        session.current?.data?.device,
        imageDimensions,
        cmd
      );

      if (coordinates !== null) {
        const { deviceX, deviceY } = coordinates;

        console.log(`2 seconds long press at x=${deviceX} y=${deviceY}`);
        await handleExecuteCommand(() =>
          session.current.data.tap({
            coordinates: { x: deviceX, y: deviceY },
            duration: 2000,
          })
        );
      }
    },
    [session.current]
  );

  const handleDoubleTap = useCallback(
    async (cmd: string, imageDimensions: ImageDimensions) => {
      const coordinates = getCoordinatesForDevice(
        session.current?.data?.device,
        imageDimensions,
        cmd
      );

      if (coordinates !== null) {
        const { deviceX, deviceY } = coordinates;

        console.log(`Double tapping at x=${deviceX} y=${deviceY}`);
        await handleExecuteCommand(() =>
          session.current.data.tap({
            coordinates: { x: deviceX, y: deviceY },
          })
        );
        console.log("tap");
        await handleExecuteCommand(() =>
          session.current.data.tap({
            coordinates: { x: deviceX, y: deviceY },
          })
        );
        console.log("tap");
        await handleExecuteCommand(() =>
          session.current.data.tap({
            coordinates: { x: deviceX, y: deviceY },
          })
        );
        console.log("tap");
        await handleExecuteCommand(() =>
          session.current.data.tap({
            coordinates: { x: deviceX, y: deviceY },
          })
        );
        console.log("tap");
        await handleExecuteCommand(() =>
          session.current.data.tap({
            coordinates: { x: deviceX, y: deviceY },
          })
        );
        console.log("tap");
      }
    },
    [session.current]
  );

  const handleWait = useCallback(
    async (cmd: string) => {
      const [seconds] = cmd.match(/\d+/g);
      console.log("waiting for ", seconds * 1000, "ms");
      // as there is a risk of appetize closing the session because of inactivity and their heartbeat method not working, we thought about tapping somewhere however this is causing problem with the gigxr build
      // console.log("tapping on 50%:0%");
      // await session.current.data.tap({ position: { x: "50%", y: "0%" } });
      await delay(1000 * seconds);
    },
    [session.current]
  );

  const handleGoTo = useCallback(
    async (cmd: string) => {
      const destination = cmd.split("go to:")[1].trim();

      if (destination.toLowerCase().includes("home")) {
        console.log(`Hitting HOME`);

        await handleExecuteCommand(() => session.current.data.keypress("HOME"));
      }
    },
    [session.current, settingsData]
  );

  const handleSlide = useCallback(
    async (cmd: string, imageDimensions: ImageDimensions) => {
      const regexMatch = [
        ...cmd.matchAll(/slide (left|right|up|down) (\d+)%.*;(\d+);(\d+);/g),
      ]?.[0];
      if (regexMatch) {
        const [whole, direction, percentage, xPos, yPos] = regexMatch;

        console.log(
          `sliding ${direction} ${percentage}% at x=${xPos} y=${yPos}`
        );

        await handleExecuteCommand(() =>
          session.current.data.swipe({
            gesture: (g) => g[direction](`${percentage}%`),
            position: {
              x: `${(100 * Number(xPos)) / imageDimensions.width}%`,
              y: `${(100 * Number(yPos)) / imageDimensions.height}%`,
            },
            duration: 2000, // optional, in ms
          })
        );
      } else {
        console.error(`Regex not matched! Command = ${cmd}`);
      }
    },
    [session.current]
  );

  const handleType = useCallback(
    async (cmd: string) => {
      const character = cmd.split("type:")[1].trim().replace(/"/g, "");
      console.log(`typing ${character}`);
      if (settingsData.splitTextWhenTyping) {
        for (let i = 0; i < character.length; i++) {
          await handleExecuteCommand(() =>
            session.current.data.type(character[i])
          );
        }
      } else {
        await handleExecuteCommand(() => session.current.data.type(character));
      }
    },
    [session.current, settingsData]
  );

  const handleKeypress = useCallback(
    async (cmd: string) => {
      const key = cmd.split("press:")[1].trim();

      if (key.toLowerCase() === "enter") {
        console.log(`Hitting Enter`);
        await handleExecuteCommand(() =>
          session.current.data.keypress("Enter")
        );
      }
    },
    [session.current, settingsData]
  );

  const handleSwipeOrScroll = useCallback(
    async (cmd: string) => {
      const regexMatch = [
        ...cmd.matchAll(
          /(swipe|scroll): (right|left|up|down)\s?((\d+) times)?/g
        ),
      ]?.[0];

      if (regexMatch) {
        const swipeOrScroll = regexMatch[1];
        const initialDirection = regexMatch[2];
        const numberOfInteractions = parseInt(regexMatch[4] ?? "1");

        let movementDirection: string = "up";
        movementDirection =
          swipeOrScroll == "scroll"
            ? invertDirection(initialDirection)
            : initialDirection;

        // swipe up at middle of the screen
        const verticalMovement = ["up", "down"].includes(movementDirection);

        for (let i = 0; i < numberOfInteractions; i++) {
          console.log(
            `Performing ${swipeOrScroll} ${initialDirection} ${
              i + 1
            }/${numberOfInteractions}`
          );
          await handleExecuteCommand(() =>
            session.current.data.swipe({
              gesture: (g) =>
                g[movementDirection](verticalMovement ? "50%" : "100%"),
              position: {
                x: verticalMovement
                  ? "50%"
                  : movementDirection === "left"
                  ? "90%"
                  : "10%",
                y: verticalMovement
                  ? movementDirection === "down"
                    ? "60%"
                    : "40%"
                  : "50%",
              },
              duration: 3000, // optional, in ms
            })
          );
        }
      } else {
        console.log("Regex not matched! cmd = ", cmd);
      }
    },
    [session.current]
  );

  const handleRemoveText = useCallback(
    async (cmd: string) => {
      let text = cmd.split(":")[1].trim();
      text = text.replace(/"/g, "");
      console.log("removing " + text);
      for (let i = 0; i < text.length; i++) {
        await delay(100 * i);
        console.log("Hitting backspace");
        await handleExecuteCommand(() =>
          session.current.data.keypress("Backspace")
        );
      }
    },
    [session.current]
  );

  const handleOpenDeepLink = useCallback(
    async (cmd: string) => {
      let link = cmd.split("deeplink:")[1]?.trim();
      link = link.replace(/"/g, ""); // Remove all double quotes
      console.log("Opening deeplink ", link);
      await handleExecuteCommand(() => session.current.data.openUrl(link));
    },
    [session.current]
  );

  const handleShakeDevice = useCallback(
    async () =>
      await handleExecuteCommand(async () => {
        if (session.current.data?.config?.platform === "android") {
          const command = "input keyevent 82";
          console.log("Shaking the device with command: ", command);
          await session.current.data.adbShellCommand(command);
        } else {
          await session.current.data.shake();
        }
      }),
    [session.current, buildData]
  );

  const handleRestartApp = useCallback(async () => {
    console.log(">> Restarting the app");
    await handleExecuteCommand(() => session.current.data.restartApp());
  }, [session.current]);

  const handleExecute = useCallback(
    async (cmd: string) => {
      const regexMatch = [
        ...cmd.matchAll(/.*(GET|DELETE).*url=(.*);json selector=(.*)/g),
      ]?.[0];
      const jsonlessRegexMatch = [
        ...cmd.matchAll(/.*(GET|DELETE).*url=(.*)/g),
      ]?.[0];
      let [whole, method, url, json_selector] = ["", "", "", ""];
      if (regexMatch) {
        [whole, method, url, json_selector] = regexMatch;
      } else if (jsonlessRegexMatch) {
        [whole, method, url] = jsonlessRegexMatch;
      } else {
        throw new Error(`Incorrect command passed: ${cmd}`);
      }

      console.log(
        `Executing ${method} network call to ${url} with json selector = ${json_selector}`
      );

      try {
        const response_json = await executeCurlCommand(
          `curl -X ${method} ${url}`,
          buildData?.organisationId!
        );

        console.log("Response ", response_json);
        const data = getPropByString(response_json.data, json_selector);
        console.log("Data", data);

        gptStepsRef.current[gptStepsRef.current.length - 1] = [
          ...gptStepsRef.current[gptStepsRef.current.length - 1],
          `remember: "network data value":"${JSON.stringify(data)}"`,
        ];
      } catch (e) {
        trackSentryException(e);
        if (e instanceof SyntaxError) {
          console.error(
            "Error occured while handling json selector for network request",
            e
          );
        } else {
          throw e;
        }
      }
    },
    [session.current, buildData]
  );

  const handleVerifyEmail = useCallback(
    async (cmd: string) => {
      const email = cmd.match(/email=(.*);link_pattern=(.*)/)?.[1];
      const link_pattern = cmd.match(/email=(.*);link_pattern=(.*)/)?.[2];
      if (email === undefined || link_pattern === undefined) {
        throw new Error(
          `Incorrect data passed: email=${email}, json link_pattern=${link_pattern}`
        );
      }
      console.log(
        `Veryfying email ${email} with link_pattern = ${link_pattern}`
      );
      let link;
      for (let i = 0; i < 2; i++) {
        try {
          link = await callEmailReaderAPI(email, link_pattern);
          if (link) {
            gptStepsRef.current[gptStepsRef.current.length - 1] = [
              ...gptStepsRef.current[gptStepsRef.current.length - 1],
              `remember: "email data value":"${JSON.stringify(link)}"`,
            ];
            break;
          }
        } catch (e) {
          trackSentryException(e);
          console.log("Error occurred during execution of network call", e);
          console.log("Waiting 10 seconds and retrying...");
          await new Promise((r) => setTimeout(r, 1000 * 10));
        }
      }
      if (!link) {
        throw new Error(`Incorrect link = ${link}`);
      }
    },
    [session.current]
  );

  const handleCheckAudio = useCallback(() => {
    if (wasAudioPlayingInTheLastThreeSeconds(audioFramesRef.current)) {
      gptStepsRef.current[gptStepsRef.current.length - 1] = [
        ...gptStepsRef.current[gptStepsRef.current.length - 1],
        `remember: "audio was being played"`,
      ];
    } else {
      gptStepsRef.current[gptStepsRef.current.length - 1] = [
        ...gptStepsRef.current[gptStepsRef.current.length - 1],
        `remember: "audio was not being played"`,
      ];
    }
  }, [session.current]);

  const commandHandler = useCallback(
    async (cmd: string, imageDimensions: ImageDimensions) => {
      if (cmd.includes("tabOn:")) {
        await handleTabOn(cmd, imageDimensions);
      } else if (cmd.includes("set location:")) {
        await handleSetLocation(cmd);
      } else if (cmd.includes("tap in sequence:")) {
        await handleTabOn(cmd, imageDimensions);
      } else if (cmd.includes("double tap:")) {
        await handleDoubleTap(cmd, imageDimensions);
      } else if (cmd.includes("long press:")) {
        await handleLongPress(cmd, imageDimensions);
      } else if (cmd.includes("wait:")) {
        await handleWait(cmd);
      } else if (cmd.includes("type:")) {
        await handleType(cmd);
      } else if (cmd.includes("press:")) {
        await handleKeypress(cmd);
      } else if (cmd.includes("scroll:")) {
        await handleSwipeOrScroll(cmd);
      } else if (cmd.includes("removeText:")) {
        await handleRemoveText(cmd);
      } else if (cmd.includes("open deeplink:")) {
        await handleOpenDeepLink(cmd);
      } else if (cmd.includes("verify email:")) {
        await handleVerifyEmail(cmd);
      } else if (cmd.includes("swipe")) {
        await handleSwipeOrScroll(cmd);
      } else if (cmd.includes("slide")) {
        await handleSlide(cmd, imageDimensions);
      } else if (cmd.includes("execute:")) {
        await handleExecute(cmd);
      } else if (cmd.includes("check: audio")) {
        handleCheckAudio();
      } else if (cmd.includes("go to:")) {
        await handleGoTo(cmd);
      } else if (cmd.includes("shake the device")) {
        await handleShakeDevice();
      } else if (cmd.includes("restart")) {
        await handleRestartApp();
      }
    },
    [
      handleTabOn,
      handleDoubleTap,
      handleWait,
      handleType,
      handleRemoveText,
      handleExecute,
      handleSwipeOrScroll,
      handleVerifyEmail,
      handleOpenDeepLink,
      handleCheckAudio,
      handleShakeDevice,
      session.current,
    ]
  );

  return commandHandler;
};

export default useCommandHandler;
