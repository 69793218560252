import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import * as Sentry from "@sentry/react";
import { getFunctions, httpsCallable } from "firebase/functions";
import * as amplitude from "@amplitude/analytics-browser";
import { BACKEND_SERVER_URL, LAMBDA_AWS_URL } from "./constants/aws-constants";

if (import.meta.env.MODE !== "development") {
  const replay = new Sentry.Replay({
    maskAllText: false,
    blockAllMedia: false,
    maskAllInputs: false,
    networkDetailAllowUrls: [
      LAMBDA_AWS_URL,
      `${BACKEND_SERVER_URL}/createSuite`,
      `${BACKEND_SERVER_URL}/curl`,
      "identitytoolkit.googleapis.com",
      "https://us-central1-flutter-feedback.cloudfunctions.net",
    ],
  });

  Sentry.addTracingExtensions();
  Sentry.init({
    dsn: "https://a352a6302eca4c04afaf90cd4a0d5793@o4504564674002944.ingest.sentry.io/4505328943366144",
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 0,
    tracesSampleRate: 1.0,
    integrations: [replay],
  });

  amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
    defaultTracking: true,
  });
}

const firebaseConfig =
  import.meta.env.MODE === "development"
    ? {
        apiKey: "AIzaSyBv63HuteWB2PE2UxfUMltizULLKhjI4JU",
        authDomain: "flutter-feedback-dev.firebaseapp.com",
        projectId: "flutter-feedback-dev",
        storageBucket: "flutter-feedback-dev.appspot.com",
        messagingSenderId: "1027629497749",
        appId: "1:1027629497749:web:efef262016a8af191e8a9c",
      }
    : {
        apiKey: "AIzaSyDtzPICCCUZRjzk3VEoCEuR_RFDVoaFO7Q",
        authDomain: "flutter-feedback.firebaseapp.com",
        projectId: "flutter-feedback",
        storageBucket: "flutter-feedback.appspot.com",
        messagingSenderId: "186075198856",
        appId: "1:186075198856:web:f0f71c7f0ac8a58a7ab63d",
      };

const app = initializeApp(firebaseConfig);

const customStorage = getStorage(app);
const customFirestore = getFirestore(app);
const firebaseAuth = getAuth(app);

const uiConfig = {
  signInFlow: "popup",
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    signInSuccess: () => false,
  },
};

const functions = getFunctions(app);
const customClaimsMigrationSetter = httpsCallable(
  functions,
  "customClaimsMigration"
);

const changeCompanyCustomClaimsSetter = httpsCallable(
  functions,
  "changeCompanyCustomClaims"
);

const tipTapCollabJWTGenerator = httpsCallable(
  functions,
  "tipTapCollabJWTGenerator"
);

const transferTemplatesHandler = httpsCallable(
  functions,
  "transferTemplatesHandler"
);

export {
  customStorage,
  customFirestore,
  firebaseAuth,
  uiConfig,
  customClaimsMigrationSetter,
  changeCompanyCustomClaimsSetter,
  tipTapCollabJWTGenerator,
  transferTemplatesHandler,
};
