import {
  onAuthStateChanged,
  signInWithCustomToken,
  signOut,
  signInAnonymously,
} from "firebase/auth";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { customClaimsMigrationSetter, firebaseAuth } from "../firebase";
import { type UserType } from "../utils/types";
import {
  getCompanyNameFromOrganisationId,
  updateUsersCompany,
} from "../utils/firebase_operations";
import * as Sentry from "@sentry/react";
import { User } from "firebase/auth";
import { trackAmplitudeEvent, trackSentryException } from "../utils/helpers";
import * as amplitude from "@amplitude/analytics-browser";
import { Outlet, useLocation } from "react-router-dom";

export const AuthContext = createContext({});
export const AuthContextProvider = () => {
  const [user, setUser] = useState<UserType>({
    email: null,
    uid: null,
    companyName: undefined,
    companyId: undefined,
    role: undefined,
    avatarUrl: undefined,
    displayName: undefined,
  });
  const [loadingUser, setLoadingUser] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const createUserContext = useCallback(
    async (organisationId: string, role: string, user: User) => {
      const companyName = await getCompanyNameFromOrganisationId(
        organisationId
      );

      setUser({
        email: user.email,
        displayName: user.displayName,
        uid: user.uid,
        companyName,
        companyId: organisationId,
        avatarUrl: user.photoURL,
        role,
        isAnonymous: user.isAnonymous,
      });

      Sentry.setUser({ email: user.email! });
      Sentry.setTag("organisationId", organisationId);

      amplitude.setUserId(user.email!);
      amplitude.setGroup("organisation", companyName!);

      // disable console logging for non-kitchenful users
      if (
        companyName !== "kitchenful" &&
        role !== "superUser" &&
        import.meta.env.MODE !== "development"
      ) {
        console.log = () => {};
        console.warn = () => {};
        console.error = () => {};
      }
    },
    []
  );

  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      setLoadingUser(true);
      if (user != null) {
        if (user.isAnonymous) {
          const recordingRouteRegex = /^\/recording\/[^\/]+\/runs\/[^\/]+$/;
          if (!recordingRouteRegex.test(location.pathname)) {
            await signOut(firebaseAuth);
            return;
          }

          setUser({
            email: null,
            uid: user.uid,
            companyName: undefined,
            companyId: undefined,
            role: undefined,
            avatarUrl: undefined,
            displayName: undefined,
            isAnonymous: true,
          });
        } else {
          const parsedToken = await Sentry.startSpan(
            { name: "getIdTokenResult 1" },
            async () => await user.getIdTokenResult(true)
          );

          if (!parsedToken.claims?.organisationId) {
            try {
              await customClaimsMigrationSetter();
              const newParsedToken = await Sentry.startSpan(
                { name: "getIdTokenResult 2" },
                async () => user.getIdTokenResult(true)
              );
              await createUserContext(
                newParsedToken.claims.organisationId,
                newParsedToken.claims.role,
                user
              );
            } catch (e) {
              trackSentryException(e);
              await signOut(firebaseAuth);
            }
          } else {
            await createUserContext(
              parsedToken.claims.organisationId,
              parsedToken.claims.role,
              user
            );
          }

          window.pylon = {
            chat_settings: {
              app_id: import.meta.env.VITE_PYLON_APP_ID,
              email: user.email,
              name: user.displayName,

              // (Optional) If you have the user's profile image available through something like Auth0
              avatar_url: user.photoURL,

              // (Optional) If you are using Pylon's identity verification
              // email_hash: "USER_EMAIL_HASH",
              closed: true,
            },
          };
        }
      } else {
        const recordingRouteRegex = /^\/recording\/[^\/]+\/runs\/[^\/]+$/;
        if (recordingRouteRegex.test(location.pathname)) {
          await signInAnonymously(firebaseAuth);
          return;
        } else {
          setUser({
            email: null,
            uid: null,
            companyName: undefined,
            companyId: undefined,
            role: undefined,
            avatarUrl: undefined,
            displayName: undefined,
            isAnonymous: undefined,
          });
        }
      }
      setLoadingUser(false);
    });

    return () => unsubscribe();
  }, []);

  const logOut = useCallback(async () => {
    setLoading(true);
    // setUser({ email: null, uid: null });
    await signOut(firebaseAuth);
    setLoading(false);
  }, []);

  const signInWithToken = useCallback(async (token: string) => {
    await signInWithCustomToken(firebaseAuth, token);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, logOut, loading, loadingUser, signInWithToken }}
    >
      <Outlet />
    </AuthContext.Provider>
  );
};
