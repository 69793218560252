import { atomFamily, atomWithReset } from "jotai/utils";
import { Node } from "prosemirror-model";
import {
  getTipTapExtensions,
  getTipTapSchema,
  trackSentryException,
} from "../utils/helpers";
import { atomWithQuery } from "jotai-tanstack-query";
import { BuildData, Dependency, Test, UnparsedTest } from "../utils/types";
import { customFirestore, customStorage } from "../firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import axios from "axios";
import { generateJSON } from "@tiptap/react";
import buildDataAtom from "./buildDataAtom";
import { companyDataAtom } from "../routes/test-run/test-run-atoms";

const defaultTest = Node.fromJSON(getTipTapSchema(), {
  type: "doc",
  content: [
    {
      type: "orderedList",
      attrs: {
        start: 1,
      },
      content: [
        {
          type: "listItem",
          content: [
            {
              type: "paragraph",
            },
          ],
        },
      ],
    },
  ],
});

interface TestEditorPropertiesType {
  originalTestCommands: Node;
  testCommands: Node;
  recordingTitle: string;
  originalRecordingTitle: string;
  hasGeneratedEnrichedLogs: boolean;
}

const testEditorPropertiesAtom = atomWithReset<TestEditorPropertiesType>({
  originalTestCommands: defaultTest,
  testCommands: defaultTest,
  recordingTitle: "Untitled",
  originalRecordingTitle: "",
  hasGeneratedEnrichedLogs: false,
});

export { testEditorPropertiesAtom };

export type { TestEditorPropertiesType };
