import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { atomWithReset } from "jotai/utils";
import {
  ParsedTestFolderItem,
  ParsedTestPreview,
  TestFolder,
  TestTemplate,
  UnparsedTest,
} from "../../utils/types";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Close, DriveFolderUpload, Inventory } from "@mui/icons-material";
import { foldersStateAtom, kAllTestsId } from "./tests-tree-view";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  deleteField,
  doc,
  runTransaction,
  writeBatch,
} from "firebase/firestore";
import { customFirestore } from "../../firebase";
import { arrayRemove, arrayUnion } from "@firebase/firestore";
import LoadingButton from "@mui/lab/LoadingButton";
import { customAlertStateAtom } from "../../components/custom-alert";
import { AuthContext } from "../../auth/AuthContext";
import { testListAtom } from "../../atoms/testing-settings-atoms";
import {
  removeItemFromArray,
  replaceItemFomArray,
  trackSentryException,
} from "../../utils/helpers";

const getSubFolders = (
  items: (ParsedTestFolderItem | ParsedTestPreview)[],
  path?: string
): (ParsedTestFolderItem & { path: string })[] => {
  const folders = items.filter(
    (subItem) => subItem.type == "folder"
  ) as ParsedTestFolderItem[];

  const parsedSubFolders = folders
    .map((subFolder) => {
      const currentPath =
        path == undefined ? subFolder.label : `${path} > ${subFolder.label}`;

      return [
        {
          ...subFolder,
          path: currentPath,
        },
        ...getSubFolders(subFolder.children, currentPath).flat(),
      ];
    })
    .flat();

  return parsedSubFolders;
};

const MoveToFolderDialog = () => {
  const [moveToFolderState, setMoveToFolderState] = useAtom<
    TestTemplate[] | null
  >(moveToFolderStateAtom);

  const handleClose = useCallback((event: {}, reason: string) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return;
    setMoveToFolderState(null);
  }, []);

  const foldersState = useAtomValue(foldersStateAtom);

  const filteredOptions = useMemo(() => {
    if (!foldersState?.folders) {
      return [];
    }
    const allFolders = getSubFolders(foldersState.folders!);

    const getFoldersDifferentThanTestFolder = () =>
      allFolders.filter(
        (folder) => folder.id != moveToFolderState?.at(0)?.folderId
      );

    if (foldersState.selectedFolderId === kAllTestsId) {
      const allTestsBelongToSameFolder =
        [...new Set(moveToFolderState?.map((folder) => folder.folderId))]
          .length === 1;
      return allTestsBelongToSameFolder
        ? getFoldersDifferentThanTestFolder()
        : allFolders;
    }

    return getFoldersDifferentThanTestFolder();
  }, [foldersState, moveToFolderState]);

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const setCustomAlertState = useSetAtom(customAlertStateAtom);
  const queryClient = useQueryClient();

  const { user } = useContext<any>(AuthContext);

  const moveToFolderMutation = useMutation({
    mutationKey: ["moveToFolderMutation"],
    mutationFn: async () => {
      const parsedSelectedOption = selectedOption ?? filteredOptions.at(0)?.id;
      const newFolderDoc = doc(
        customFirestore,
        "folders",
        parsedSelectedOption!
      );

      await runTransaction(customFirestore, async (transaction) => {
        const tests: UnparsedTest[] = (
          await Promise.all(
            moveToFolderState!.map(async (test) => {
              const testDoc = doc(customFirestore, "custom-tests", test!.id);
              const docSnap = await transaction.get(testDoc);
              return { ...(docSnap.data() as UnparsedTest), id: testDoc.id };
            })
          )
        ).filter((test) => test);

        const oldFoldersIds: string[] = tests.reduce<string[]>(
          (prev, current) => {
            return current.folderId != null
              ? prev.includes(current.folderId!)
                ? prev
                : [...prev, current.folderId]
              : prev;
          },
          []
        );

        const oldFolders: TestFolder[] = await Promise.all(
          oldFoldersIds.map(async (oldFolderId) => ({
            ...((
              await transaction.get(
                doc(customFirestore, "folders", oldFolderId)
              )
            ).data() as TestFolder),
            id: oldFolderId,
          }))
        );

        for (const testToMove of tests) {
          const testDoc = doc(customFirestore, "custom-tests", testToMove!.id);
          transaction.update(testDoc, {
            folderId: parsedSelectedOption,
          });
          if (!testToMove!.folderId) {
            transaction.update(newFolderDoc, {
              tests: arrayUnion({
                id: testToMove!.id,
                title: testToMove!.title,
                ...(testToMove!.updatedAt != null && {
                  updatedAt: testToMove!.updatedAt,
                }),
              }),
            });
          } else {
            const oldFolderIndex = oldFolders.findIndex(
              (oldFolderItem) => oldFolderItem.id === testToMove!.folderId!
            )!;

            const oldTestIndex = (
              oldFolders[oldFolderIndex].tests ?? []
            ).findIndex((test) => test.id === testToMove.id)!;

            const newTestsForOldFolder = removeItemFromArray(
              oldFolders[oldFolderIndex].tests ?? [],
              oldTestIndex
            );
            oldFolders[oldFolderIndex].tests = newTestsForOldFolder;

            const oldFolderDoc = doc(
              customFirestore,
              "folders",
              testToMove!.folderId!
            );

            transaction.update(oldFolderDoc, {
              tests: newTestsForOldFolder,
            });

            transaction.update(newFolderDoc, {
              tests: arrayUnion({
                id: testToMove!.id,
                title: testToMove!.title,
                ...(testToMove!.updatedAt != null && {
                  updatedAt: testToMove!.updatedAt,
                }),
              }),
            });
          }
        }
      });

      queryClient.removeQueries({
        queryKey: ["geTestListQuery"],
      });

      const foldersToRefresh = [
        ...new Set(moveToFolderState?.map((folder) => folder.folderId)),
        kAllTestsId,
      ];
      foldersToRefresh.forEach((folderId) => {
        testListAtom.remove({
          organizationId: user.companyId,
          folderId: folderId,
        });
      });
      testListAtom.remove({
        organizationId: user.companyId,
        folderId: parsedSelectedOption!,
      });
      await queryClient.invalidateQueries({
        queryKey: ["tests-folders-query"],
      });

      setCustomAlertState({
        open: true,
        type: "success",
        title: `Test${moveToFolderState!.length > 1 ? "s" : ""} ha${
          moveToFolderState!.length > 1 ? "ve" : "s"
        } been assigned to folder`,
      });
      setSelectedOption(null);
      setMoveToFolderState(null);
    },
    onError: (error) => {
      console.log(error);
      trackSentryException(error);
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: "An error occurred while moving test/s to folder",
      });
    },
  });

  const removeFromFolderMutation = useMutation({
    mutationKey: ["removeFromFolderMutation"],
    mutationFn: async () => {
      await runTransaction(customFirestore, async (transaction) => {
        const tests: UnparsedTest[] = (
          await Promise.all(
            moveToFolderState!.map(async (test) => {
              const testDoc = doc(customFirestore, "custom-tests", test!.id);
              const docSnap = await transaction.get(testDoc);
              return { ...(docSnap.data() as UnparsedTest), id: testDoc.id };
            })
          )
        ).filter((test) => test);

        const oldFoldersIds: string[] = tests.reduce<string[]>(
          (prev, current) => {
            return current.folderId != null
              ? prev.includes(current.folderId!)
                ? prev
                : [...prev, current.folderId]
              : prev;
          },
          []
        );

        const oldFolders: TestFolder[] = await Promise.all(
          oldFoldersIds.map(async (oldFolderId) => ({
            ...((
              await transaction.get(
                doc(customFirestore, "folders", oldFolderId)
              )
            ).data() as TestFolder),
            id: oldFolderId,
          }))
        );

        for (const testToMove of tests) {
          const testDoc = doc(customFirestore, "custom-tests", testToMove!.id);
          transaction.update(testDoc, {
            folderId: deleteField(),
          });

          const oldFolderIndex = oldFolders.findIndex(
            (oldFolderItem) => oldFolderItem.id === testToMove!.folderId!
          )!;

          const oldTestIndex = (
            oldFolders[oldFolderIndex].tests ?? []
          ).findIndex((test) => test.id === testToMove.id)!;

          const newTestsForOldFolder = removeItemFromArray(
            oldFolders[oldFolderIndex].tests ?? [],
            oldTestIndex
          );
          oldFolders[oldFolderIndex].tests = newTestsForOldFolder;

          const oldFolderDoc = doc(
            customFirestore,
            "folders",
            testToMove!.folderId!
          );

          transaction.update(oldFolderDoc, {
            tests: newTestsForOldFolder,
          });
        }
      });

      queryClient.removeQueries({
        queryKey: ["geTestListQuery"],
      });
      const foldersToRefresh = [
        ...new Set(moveToFolderState?.map((folder) => folder.folderId)),
        kAllTestsId,
      ];
      foldersToRefresh.forEach((folderId) => {
        testListAtom.remove({
          organizationId: user.companyId,
          folderId: folderId,
        });
      });

      await queryClient.invalidateQueries({
        queryKey: ["tests-folders-query"],
      });

      setCustomAlertState({
        open: true,
        type: "success",
        title: `Test${moveToFolderState!.length > 1 ? "s" : ""} ha${
          moveToFolderState!.length > 1 ? "ve" : "s"
        } been removed to folder`,
      });

      setSelectedOption(null);
      setMoveToFolderState(null);
    },
    onError: (error) => {
      console.log(error);
      trackSentryException(error);
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: "An error occurred while removing test/s from folder",
      });
    },
  });

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={!!moveToFolderState}
      onClose={handleClose}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ paddingRight: "8px", paddingTop: "10px" }}
      >
        <Box display="flex" alignItems="top">
          <Box flexGrow={1} paddingRight={"6px"} paddingTop={"4px"}>
            Move test to folder
          </Box>
          <Box>
            <Tooltip title={"Close"}>
              <span>
                <IconButton
                  disabled={
                    moveToFolderMutation.isPending ||
                    removeFromFolderMutation.isPending
                  }
                  onClick={() => handleClose({}, "closeIconButtonClose")}
                >
                  <Close />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={"flex flex-col gap-3.5"}>
        <FormControl fullWidth>
          <InputLabel id="folders-label">Folders</InputLabel>
          <Select
            fullWidth
            labelId={"folders-label"}
            label={"Folders"}
            autoWidth={true}
            value={selectedOption ?? filteredOptions.at(0)?.id}
            onChange={(event) => setSelectedOption(event.target.value)}
            disabled={filteredOptions.length == 0}
          >
            {filteredOptions.map((folder) => (
              <MenuItem key={folder.id} id={folder.id} value={folder.id}>
                {folder.path}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        {moveToFolderState != null &&
          moveToFolderState.every((test) => test?.folderId != null) && (
            <LoadingButton
              loading={removeFromFolderMutation.isPending}
              disabled={moveToFolderMutation.isPending}
              onClick={() => removeFromFolderMutation.mutate()}
              loadingPosition="start"
              startIcon={<Inventory />}
              variant="outlined"
            >
              Remove from folder
            </LoadingButton>
          )}
        <LoadingButton
          loading={moveToFolderMutation.isPending}
          disabled={removeFromFolderMutation.isPending}
          onClick={() => moveToFolderMutation.mutate()}
          loadingPosition="start"
          startIcon={<DriveFolderUpload />}
          variant="contained"
        >
          Move
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const moveToFolderStateAtom = atomWithReset<TestTemplate[] | null>(null);

export default MoveToFolderDialog;

export { moveToFolderStateAtom, getSubFolders };
