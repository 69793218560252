import { atomFamily } from "jotai/utils";
import { atomWithQuery } from "jotai-tanstack-query";
import { doc, getDoc } from "firebase/firestore";
import { customFirestore } from "../firebase";
import { BuildData } from "../utils/types";

const buildDataAtom = atomFamily((buildId?: string) => {
  return atomWithQuery((get) => ({
    queryKey: ["buildData", buildId],
    queryFn: async () => {
      const uploadDocRef = doc(customFirestore, "uploads", buildId!);
      const uploadSnap = await getDoc(uploadDocRef);
      if (uploadSnap.exists()) {
        const buildData =  uploadSnap.data() as BuildData;
        return {id: uploadSnap.id,...buildData}
      } else {
        throw Error("Build doesn't exist");
      }
    },
    enabled: buildId != null,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  }));
});

export default buildDataAtom;
