import { useCallback, useEffect, useRef } from "react";
import { Observable, Subject, Subscriber } from "rxjs";
import { retry, takeUntil } from "rxjs/operators";
import {
  callGetCachedScreenAPI,
  compareImages,
  delay,
  executeCurlCommand,
  flattenArray,
  getErrorMessage,
  getExecutionData,
  getImageDimensions,
  getInputValues,
  getPromptStepsText,
  getValueByPath,
  loadAndCropImage,
  parseCommands,
  parseGptCommandsResponse,
  parsePrompt,
  prepareGetUIElements,
  prepareDuolingoAnswerSheet,
  replaceEnvVars,
  retryOperation,
  trackExceptionOnFirestore,
  trackSentryException,
  transformTemplates,
  uint8ArrayToBase64,
  isUnusableCachedScreen,
  getFormattedDate,
} from "../../utils/helpers";

import {
  AudioFrame,
  BuildData,
  CachedScreenData,
  CompanyData,
  EnvVar,
  InputValue,
  InteractionLog,
  PreRequest,
  PreRequestResponse,
  RunExecutionData,
  ScreenshotDimensions,
  ServerExecutionParams,
  VideoFrame,
} from "../../utils/types";
import { useAtom, useAtomValue } from "jotai";
import {
  appetizeClientAtom,
  buildLogsAtomWithId,
  sessionAtom,
} from "../../atoms";
import { removeCoordinates } from "./helpers";
import useCommandHandler from "../../components/use-command-handler";

import * as Sentry from "@sentry/react";
import {
  kDelayAtSessionStartTime,
  kEditorDelayAtSessionStartTime,
} from "../../constants/appetize-constants";
import { AxiosError, isAxiosError } from "axios";
import Timeout from "await-timeout";

export function useStepObservable({
  fromEditor,
  buildData,
  companyData,
}: {
  fromEditor: boolean;
  buildData: BuildData;
  companyData: CompanyData;
}) {
  const sameScreenshotsCounter = useRef(0);
  const currentStep = useRef(0);
  const currentCachedStep = useRef(0);
  const gptSteps = useRef<any[]>([]);
  const audioFrames = useRef<AudioFrame[]>([]);
  const videoFrames = useRef<VideoFrame[]>([]);
  const companyDataRef = useRef<CompanyData>(companyData);
  const croppedScreenshots = useRef<any[]>([]);
  const [session, setSession] = useAtom(sessionAtom);
  const currentSessionRef = useRef(session);
  const currentSessionStartedAt = useRef(0);
  const commandHandler = useCommandHandler({
    session: currentSessionRef,
    gptStepsRef: gptSteps,
    audioFramesRef: audioFrames,
    settingsData: companyData.settings,
    buildData,
  });
  const currentTemplateTimestamp = useRef<string | null>(null);
  const randomValuesTemplates = useRef<{ [key: string]: string }>({});
  const settingsData = companyData.settings;
  const buildLogsAtom = buildLogsAtomWithId(buildData!.id);
  const [buildLogsData, setBuildLogsData] = useAtom(buildLogsAtom);
  const retryCountRef = useRef(0);
  const appetizeClient = useAtomValue(appetizeClientAtom);
  const repeatedStepsOccurred = useRef<boolean>(false);

  const additionalUserPromptContentRef = useRef<any>("");

  useEffect(() => {
    if (["Duolingo", "kitchenful"].includes(companyData?.name ?? "")) {
      let duolingoAnswersLocal: any[] = [];

      const duolingoNetworkCall = buildLogsData.networkLogs.find((event) => {
        if (
          event.request.url.includes(
            "https://android-api-cf.duolingo.com/2017-06-30/sessions"
          ) &&
          event.type === "response"
        ) {
          return event;
        }
      });

      if (
        duolingoNetworkCall != null &&
        additionalUserPromptContentRef.current.length === 0
      ) {
        console.log({ duolingoNetworkCall });
        const challenges = JSON.parse(
          duolingoNetworkCall.response.content.text
        ).challenges;
        duolingoAnswersLocal = prepareDuolingoAnswerSheet(challenges);
        console.log("SETTING DUOLINGO ANSWERS", duolingoAnswersLocal);

        additionalUserPromptContentRef.current = `
        List of the quiz questions with correct answers:
        <quiz_answers_sheet>
        ${JSON.stringify(duolingoAnswersLocal, null, 2)}
        </quiz_answers_sheet>`;
      }
    }
  }, [companyData.name, buildLogsData.networkLogs]);

  useEffect(() => {
    currentSessionRef.current = session;
    if (session != null) {
      currentSessionStartedAt.current = session.startedAt;
    }
  }, [session]);

  useEffect(() => {
    audioFrames.current = buildLogsData.audioFrames;
  }, [buildLogsData.audioFrames]);

  useEffect(() => {
    videoFrames.current = buildLogsData.videoFrames;
  }, [buildLogsData.videoFrames]);

  useEffect(() => {
    companyDataRef.current = companyData;
  }, [companyData]);

  const increaseCurrentCachedStep = useCallback(
    (
      cachedScreens: CachedScreenData[],
      foundCachedScreenshot: CachedScreenData
    ) => {
      const currentlyUsedCachedIndex = cachedScreens.findIndex(
        (value) => value.screenshotUrl === foundCachedScreenshot?.screenshotUrl
      );

      console.log("Current screenshot id", currentlyUsedCachedIndex);

      currentCachedStep.current =
        currentlyUsedCachedIndex !== -1
          ? currentlyUsedCachedIndex + 1
          : currentCachedStep.current + 1;
    },
    []
  );

  const getCachedScreenshot = useCallback(
    async (
      lastScreenshot: string,
      cachedScreens: CachedScreenData[],
      topPixelsToCrop: number
    ) => {
      let NUMBER_OF_SCREENS_COMPARED = 5;
      if (
        settingsData.numberOfCachedScreensCompared &&
        settingsData.numberOfCachedScreensCompared > 0
      ) {
        NUMBER_OF_SCREENS_COMPARED = settingsData.numberOfCachedScreensCompared;
      }

      const nextCachedScreens = cachedScreens.slice(
        currentCachedStep.current,
        currentCachedStep.current + NUMBER_OF_SCREENS_COMPARED
      );
      const currentlyUsedCachedScreens = nextCachedScreens.filter(
        (value) =>
          value?.baseIndex === undefined ||
          value?.baseIndex < currentStep.current + NUMBER_OF_SCREENS_COMPARED
      );

      console.log({
        currentlyUsedCachedScreens,
        currentStep: currentStep.current,
      });

      const pixelsToCrop = {
        top: topPixelsToCrop,
        bottom: 30,
        left: 5,
        right: 5,
      };
      const cachedScreenAPIResults = await callGetCachedScreenAPI(
        lastScreenshot,
        currentlyUsedCachedScreens,
        pixelsToCrop,
        buildData!.organisationId,
        settingsData.aggressiveCachingEnabled
      );

      console.log("> Caching results", cachedScreenAPIResults);
      const matchedCachedScreenshot = cachedScreenAPIResults.find(
        (value) => value.isMatch
      )?.cachedScreenString;

      const cachedData = currentlyUsedCachedScreens.find(
        (value) =>
          matchedCachedScreenshot != null &&
          value.screenshotUrl === matchedCachedScreenshot
      );

      if (cachedData !== undefined) {
        increaseCurrentCachedStep(cachedScreens, cachedData);
        const unusableCachedScreen: boolean =
          isUnusableCachedScreen(cachedData) ?? false;

        return unusableCachedScreen ? undefined : cachedData;
      }

      return undefined;
    },
    [settingsData.numberOfCachedScreensCompared]
  );

  const getExecutionDataForInstantTapOnScreenCenter = (
    screenDimensions: ScreenshotDimensions
  ) => {
    console.log("GETTING COMMANDS FOR INSTANT TAP ON SCREEN CENTER");

    const tapX = Math.floor(screenDimensions.width / 2);
    const tapY = Math.floor(screenDimensions.height / 2);

    return {
      gptCommands: [
        `step_number: N/A`,
        "step_description: Tapping on the center of the screen",
        "actions_description: Tapping on the center of the screen",
        "step_completed: True",
        "reasoning: Tapping on the center of the screen",
        `tabOn: screen center;${tapX};${tapY};text`,
      ],
      appetizeCommands: [`tabOn: x=${tapX} y=${tapY}`],
      ocrOutput: [],
    };
  };

  const getExecutionDataFromCache = (
    foundCachedScreenshot: CachedScreenData,
    envVars: EnvVar[],
    runInputValues: { key: string; value: string }[]
  ) => {
    console.log("GETTING COMMANDS FROM CACHED SCREENSHOT");
    console.log("Found cached screen data", foundCachedScreenshot);

    const cachedReasoning =
      foundCachedScreenshot.reasoning ?? "reasoning: Action taken from cache";

    let gptCommands = [
      cachedReasoning.includes("reasoning")
        ? cachedReasoning
        : `reasoning: ${cachedReasoning}`,
      // allow dynamic {{data}} handling
      parsePrompt({
        prompt: foundCachedScreenshot.gptCommand,
        envVars,
        runInputValues,
        currentTemplateTimestamp,
      }),
    ];

    const appetizeCommands = foundCachedScreenshot.appetizeCommand
      .split("\n")
      .map((command) =>
        parsePrompt({
          prompt: command,
          envVars,
          runInputValues,
          currentTemplateTimestamp,
        })
      );

    const cachedActionsDescription: string | undefined =
      foundCachedScreenshot.actionsDescription ?? undefined;

    if (cachedActionsDescription !== undefined) {
      gptCommands.push(`actions_description: ${cachedActionsDescription}`);
    }

    gptCommands.unshift(
      ...[
        `step_number: ${
          foundCachedScreenshot.stepNumber ?? currentCachedStep.current
        }`,
        "Action taken from cache",
      ]
    ); // add step_number and action taken from cache to beginning of the array

    return {
      gptCommands,
      appetizeCommands,
      ocrOutput: [],
    };
  };

  const getServerExecutionParams = async ({
    currentVideoFrame,
    run,
    actionHistory,
    envVars,
    runInputValues,
    base64screenshot,
  }: {
    currentVideoFrame: VideoFrame;
    run: RunExecutionData;
    actionHistory: string[];
    envVars: EnvVar[];
    runInputValues: InputValue[];
    base64screenshot: string;
  }): Promise<ServerExecutionParams> => {
    const screenshotDimensions = await getImageDimensions(base64screenshot);

    let uiElements;
    let homeCalloutContainerPresent = false;
    try {
      const unparsedUiElements = await currentSessionRef.current.data.getUI({
        timeout: 5000,
      });
      const result = await prepareGetUIElements(
        unparsedUiElements,
        currentVideoFrame
      );
      uiElements = result.uiElements;
      homeCalloutContainerPresent = result.homeCalloutContainerPresent;
    } catch (e) {
      trackSentryException(e);
      uiElements = [];
    }

    let currentAndPreviousScreenMatch = false;
    //TODO probably we'll move this to an server function
    // if (croppedScreenshots.current.length >= 2) {
    //   console.log(
    //     "> COMPARING CURRENT AND PREVIOUS SCREENSHOTS TO CHECK IF THEY MATCH"
    //   );
    //   const lastScreenshots = croppedScreenshots.current.slice(-2);
    //   const comparisonResult = await compareImages(
    //     lastScreenshots[0],
    //     lastScreenshots[1],
    //     50
    //   );
    //   currentAndPreviousScreenMatch = comparisonResult < 200;
    // }

    const promptSteps = run.promptSteps;
    const promptStepsText = getPromptStepsText(promptSteps);
    console.log("promptStepsText", { promptSteps, promptStepsText });
    const { parsedCommands, parsedPromptSteps } = parseCommands({
      commands: promptStepsText,
      promptSteps,
      shortenCommandStringEnabled:
        settingsData.shortenCommandStringEnabled ?? false,
      shortenCommandStringFromTopEnabled:
        settingsData.shortenCommandStringFromTopEnabled ?? false,
      actionHistory,
      currentTemplateTimestamp,
      envVars,
      runInputValues,
    });

    const filteredActionHistory = actionHistory.filter((item) =>
      item.startsWith("[Step ")
    );

    return {
      uiElements,
      currentAndPreviousScreenMatch,
      tapInstantlyOnScreenCenter: homeCalloutContainerPresent,
      commands: parsedCommands,
      promptSteps: parsedPromptSteps,
      envVars,
      runInputValues,
      actionHistory: filteredActionHistory,
      screenshotDimensions,
      step: currentStep.current,
      testId: run.testId,
      isPartialExecution: run.isPartialExecution ?? false,
    };
  };

  const getExecutionDataFromServer = async ({
    base64screenshot,
    buildId,
    params,
  }: {
    base64screenshot: string;
    buildId: string;
    params: ServerExecutionParams;
  }) => {
    console.log("GETTING COMMANDS FROM GPT CALL REQUEST");

    if (additionalUserPromptContentRef.current !== "") {
      console.log("additionalUserPromptContentRef.current", {
        current: additionalUserPromptContentRef.current,
      });
    }

    const currentDate = getFormattedDate(new Date());
    const requestObject = {
      lambda_flow: "get_next_step",
      current_date: currentDate,
      base64_screenshot: base64screenshot.split(",")[1],
      getUI_elements: params.uiElements,
      test_task_string: JSON.stringify(params.promptSteps, null, 2),
      image_width: params.screenshotDimensions.width - 1, // subtract 1, since appetize allows pixels in range <0, image_dimension - 1>
      image_height: params.screenshotDimensions.height - 1,
      action_history: params.actionHistory,
      orgKey: buildData.organisationId,
      uploadId: buildId,
      platform: buildData.platform,
      executionId: 123,
      step: params.step,
      template_images: transformTemplates(
        companyDataRef.current.templates ?? []
      ),
      model_provider: "vellum",
      model_version: repeatedStepsOccurred.current
        ? "explain-repeated-actions"
        : fromEditor
        ? companyData.editorExecutionModel
        : companyData.serverExecutionModel,
      fallbackModel: companyData.fallbackModel,
      utilize_fullTextAnnotation: settingsData.utilizeFullTextAnnotation,
      enableSortingOCR: settingsData.enableSortingOCR,
      enableActionHistoryCut: settingsData.enableActionHistoryCut,
      removeOverlappingText: settingsData.removeOverlappingText,
      currentAndPreviousScreenMatch: params.currentAndPreviousScreenMatch,
      popupDetectionEnabled: settingsData.popupDetectionEnabled,
      ocrProvider: settingsData.ocrProvider,
      modelResponseDoubleCheckEnabled:
        !fromEditor && settingsData.modelResponseDoubleCheckEnabled,
      additionalUserPromptContent: additionalUserPromptContentRef.current,
      popupDetectionParameters: settingsData.popupDetectionParameters,
      templatesDetectionParameters: settingsData.templatesDetectionParameters,
      disabledButtonsDetectionEnabled:
        settingsData.disabledButtonsDetectionEnabled,
      disabledButtonsDetectionParameters:
        settingsData.disabledButtonsDetectionParameters,
    };
    console.log("requestObject", requestObject);

    return await getExecutionData(requestObject);
  };

  const executeCommandsSequentially = useCallback(
    async (
      observer: Subscriber<unknown>,
      currentRunIndex: number,
      runs: RunExecutionData[],
      appetizeCommandsToExecute: string[],
      gptCommandToDisplay: string,
      screenshotDimensions: ScreenshotDimensions
    ) => {
      const run = runs[currentRunIndex];
      const parentRun = runs.at(-1);
      let shouldIncreaseCurrentStep = false;

      if (appetizeCommandsToExecute.length === 0 && !observer.closed) {
        shouldIncreaseCurrentStep = true;
      } else {
        observer.next({
          type: "APPETIZE_COMMANDS_TO_EXECUTE",
          data: {
            appetizeCommands: appetizeCommandsToExecute,
          },
        });

        for (let i = 0; i < appetizeCommandsToExecute.length; i++) {
          if (observer.closed) {
            break;
          }

          const cmd = appetizeCommandsToExecute[i];
          const isLastCommand = i === appetizeCommandsToExecute.length - 1;

          if (cmd.toLowerCase().includes("task complete:")) {
            if (currentRunIndex === runs.length - 1) {
              observer.next({
                type: "RUN_COMPLETED",
                data: {
                  status: "succeeded",
                  message: gptCommandToDisplay,
                  run,
                },
              });
            } else {
              observer.next({
                type: "RUN_COMPLETED",
                data: {
                  status: "succeeded",
                  message: gptCommandToDisplay,
                  parentRun,
                  run,
                },
              });
            }
          } else if (cmd.toLowerCase().includes("error detected:")) {
            if (currentRunIndex === runs.length - 1) {
              emitRunError(gptCommandToDisplay, run, observer);
            } else {
              emitRunError(gptCommandToDisplay, run, observer, parentRun);
            }
          } else {
            try {
              if (observer.closed) {
                break;
              }
              console.log("calling command handler with cmd: ", cmd);

              observer.next({
                type: "STEP_PROCESSING_TEXT_UPDATED",
                data: {
                  text: "Executing...",
                },
              });
              await retryOperation(
                () =>
                  commandHandler(cmd, {
                    width: screenshotDimensions.width,
                    height: screenshotDimensions.height,
                  }),
                1000,
                3
              );
              observer.next({
                type: "STEP_PROCESSING_TEXT_UPDATED",
                data: {
                  text: "Waiting for stable screen...",
                },
              });
              if (!cmd.includes("tap in sequence:") || isLastCommand) {
                const startTime = Date.now();
                try {
                  console.log(`Awaiting after command handler`);
                  await currentSessionRef.current.data.waitForAnimations(
                    {
                      timeout: settingsData.waitForStableScreenTime,
                      imageThreshold: 0.000001,
                    } // TODO decide on these values
                  );
                } catch (e) {
                  // Expected error while waiting for stable screen - no need to track
                  // trackSentryException(e);
                } finally {
                  console.log(
                    `Finished awaiting after command handler. Waiting took`,
                    (Date.now() - startTime) / 1000
                  );
                }
              }
              if (isLastCommand) {
                shouldIncreaseCurrentStep = true;
              }
            } catch (cmdError) {
              console.error("Command execution failed:", cmdError);
              await emitLastInteractionLog(observer, `${cmdError}`, run);
              if (currentRunIndex === runs.length - 1) {
                emitRunError(cmdError, run, observer);
              } else {
                emitRunError(cmdError, run, observer, parentRun);
              }
            }
          }
        }
      }
      if (shouldIncreaseCurrentStep) {
        let SAME_SCREENSHOTS_LIMIT = 5;
        let SAME_STEP_PERFORMED_LIMIT = 10;
        if (
          settingsData?.sameStepPerformedLimit &&
          settingsData.sameStepPerformedLimit > 0
        ) {
          SAME_STEP_PERFORMED_LIMIT = settingsData.sameStepPerformedLimit;
          SAME_SCREENSHOTS_LIMIT = settingsData.sameStepPerformedLimit;
        }

        if (croppedScreenshots.current.length >= 2) {
          const rememberCommandIsUsed = (
            (gptSteps.current.at(-1) as string[]) ?? []
          ).some((value) => value.includes("remember"));
          const lastScreenshots = croppedScreenshots.current.slice(-2);
          const screenshotsEqual =
            (await compareImages(lastScreenshots[0], lastScreenshots[1])) === 0;
          console.log("screenshotsEqual", screenshotsEqual);
          if (screenshotsEqual && !rememberCommandIsUsed) {
            sameScreenshotsCounter.current += 1;
          } else {
            sameScreenshotsCounter.current = 0;
          }

          if (sameScreenshotsCounter.current >= SAME_SCREENSHOTS_LIMIT - 1) {
            repeatedStepsOccurred.current = true;
          }
        }

        if (gptSteps.current.length >= SAME_STEP_PERFORMED_LIMIT) {
          const lastGptSteps = gptSteps.current.slice(
            -SAME_STEP_PERFORMED_LIMIT
          );
          const lastStepNumbers = lastGptSteps.map(
            (value) => parseGptCommandsResponse(value).step_number
          );
          const allStepNumbersTheSame = lastStepNumbers.every(
            (val, i, arr) => val === arr[0]
          );

          if (allStepNumbersTheSame && lastStepNumbers[0] !== "") {
            repeatedStepsOccurred.current = true;
          }
        }
      }

      return shouldIncreaseCurrentStep;
    },
    []
  );

  const emitRunError = useCallback(
    (
      error,
      run: RunExecutionData,
      observer: Subscriber<unknown>,
      parentRun?: RunExecutionData
    ) => {
      retryCountRef.current = retryCountRef.current + 1;
      trackSentryException(error);
      const blocked = `${error}`.includes("500") || `${error}`.includes("502");
      observer.error({
        type: "RUN_COMPLETED",
        data: {
          status: blocked ? "blocked" : "failed",
          message: `${error}`,
          run,
          parentRun,
        },
      });
    },
    []
  );

  const getPreRequestsData = useCallback(
    async (
      run: RunExecutionData,
      envVars: EnvVar[],
      inputValues: InputValue[],
      organisationId: string
    ): Promise<{
      preRequestsGptSteps: string[];
      preRequestsResponseValues: PreRequestResponse[];
    }> => {
      let rememberGptSteps: string[] = [];
      const responseValues: { curl: string; status: number; data: any }[] = [];
      const preRequests: PreRequest[] = run.settings.preRequests;
      let internalValues: any = {};

      for (const preRequest of preRequests) {
        const filledCurlCommand = preRequest.curlCommand.replace(
          /{{(.*?)}}/g,
          (_, key: string) => {
            if (key.trim().startsWith("env.")) {
              return (
                envVars.find((envVar) => `env.${envVar.key}` === key.trim())
                  ?.value ?? ""
              );
            }
            return (
              internalValues[key.trim()] ??
              inputValues.find((value) => value.key === key.trim())?.value ??
              ""
            );
          }
        );
        if (preRequest.delayBeforeRequest > 0) {
          await delay(preRequest.delayBeforeRequest);
        }
        const curlResponse = await executeCurlCommand(
          filledCurlCommand,
          organisationId
        );
        if (isAxiosError(curlResponse)) {
          throw new Error("Pre-request failed with an error.");
        }

        responseValues.push({
          curl: preRequest.curlCommand,
          status: curlResponse.status,
          data: curlResponse.data,
        });

        if (
          preRequest.valuesToRemember != null &&
          preRequest.valuesToRemember.length > 0
        ) {
          const newRememberedValues = preRequest.valuesToRemember.map(
            (valueToRemember) => {
              const value = getValueByPath(curlResponse.data, valueToRemember);
              const valueName = valueToRemember.split(".").at(-1) ?? "";
              internalValues[valueName] = value;
              return `[Step 0] remember: "${valueName}: ${value}"`;
            }
          );
          rememberGptSteps = [...rememberGptSteps, ...newRememberedValues];
        }
      }

      return {
        preRequestsGptSteps: rememberGptSteps,
        preRequestsResponseValues: responseValues,
      };
    },
    []
  );

  const emitLastInteractionLog = useCallback(
    async (observer: Subscriber<any>, cmd: string, run: RunExecutionData) => {
      let base64screenshot: string | null = null;
      if (videoFrames.current.length > 0) {
        base64screenshot = uint8ArrayToBase64(
          videoFrames.current[videoFrames.current.length - 1]?.buffer
        );
      }
      const currentTimestamp = Date.now();
      const timestampInMs = currentTimestamp - currentSessionStartedAt.current;

      observer.next({
        type: "LAST_INTERACTION_LOG",
        data: {
          gptStep: [cmd],
          image: base64screenshot,
          run,
          interactionLog: {
            screenshot: base64screenshot,
            gptCommands: [cmd],
            relativeTimestamp: timestampInMs,
            absoluteTimestamp: currentTimestamp,
          },
        },
      });
      await delay(100);
    },
    [fromEditor]
  );

  const handleAppetizeErrors = useCallback(
    async ({
      observer,
      currentRunIndex,
      runs,
      errorMessage,
    }: {
      observer: Subscriber<any>;
      currentRunIndex: number | null;
      runs: RunExecutionData[];
      errorMessage: string;
    }) => {
      if (currentRunIndex != null && !observer.closed) {
        trackSentryException(errorMessage);
        await trackExceptionOnFirestore({
          error: errorMessage,
          source: "appetize",
        });
        await emitLastInteractionLog(
          observer,
          errorMessage,
          runs[currentRunIndex]
        );
        retryCountRef.current = retryCountRef.current + 1;
        if (currentRunIndex === runs.length - 1) {
          observer.error({
            type: "RUN_COMPLETED",
            data: {
              status: "blocked",
              message: errorMessage,
              run: runs[currentRunIndex],
            },
          });
        } else {
          observer.error({
            type: "RUN_COMPLETED",
            data: {
              status: "blocked",
              message: errorMessage,
              run: runs[currentRunIndex],
              parentRun: runs[runs.length - 1],
            },
          });
        }
      }
    },
    [emitLastInteractionLog]
  );

  const executeNextSteps = useCallback(
    async (
      runs: RunExecutionData[],
      currentRunIndex: number,
      observer: Subscriber<unknown>,
      envVars: EnvVar[]
    ) => {
      const run = runs[currentRunIndex];
      const parentRun = runs[runs.length - 1];
      const nextRunToExecute = runs[currentRunIndex + 1];
      const buildId = buildData!.id;

      if (!observer.closed) {
        observer.next({
          type: "STEP_PROCESSING_TEXT_UPDATED",
          data: {
            text: "Analysing...",
          },
        });

        try {
          let appetizeCommandsToExecute: string[] = [];

          const currentVideoFrame =
            videoFrames.current[videoFrames.current.length - 1];

          const base64screenshot = uint8ArrayToBase64(currentVideoFrame.buffer);

          observer.next({
            type: "NEW_STEP_INIT",
            data: {
              image: base64screenshot,
            },
          });

          const topPixelsToCrop =
            currentSessionRef.current?.data?.config?.platform === "ios"
              ? 80
              : 60;

          croppedScreenshots.current = [
            ...croppedScreenshots.current,
            await loadAndCropImage(base64screenshot, topPixelsToCrop),
          ];

          console.log("run.promptSteps", run.promptSteps);
          const parsedGptSteps: string[][] = gptSteps.current.map(
            (value: string[]) => {
              const { reasoning, commands, step_number, actions_description } =
                parseGptCommandsResponse(value);

              const action_history_array = [];

              if (actions_description) {
                action_history_array.push(
                  `[Step ${step_number}] ${actions_description}`
                );
              }

              if (reasoning && commands && step_number) {
                action_history_array.push(
                  ...[reasoning, `[Step ${step_number}] ${commands}`]
                );

                return action_history_array;
              } else {
                return value;
              }
            }
          );

          const action_history = removeCoordinates(
            flattenArray(parsedGptSteps)
          );
          let jsonResponse;

          const runWithCache: boolean = run.cache?.enabled ?? false;
          console.log({ run });
          let foundCachedScreenshot: CachedScreenData | undefined;
          const cachedScreens: CachedScreenData[] = run.cache?.data ?? [];

          let runInputValues: InputValue[] = getInputValues({
            currentRun: run,
            nextRunToExecute,
          });

          if (runWithCache) {
            const startTime = Date.now();
            foundCachedScreenshot = await getCachedScreenshot(
              base64screenshot,
              cachedScreens,
              topPixelsToCrop
            );
            console.log(
              `Comparing cached screens took ${(
                (Date.now() - startTime) /
                1000
              ).toFixed(2)}s`
            );
          }

          const serverExecutionParams = await getServerExecutionParams({
            currentVideoFrame,
            run,
            envVars,
            runInputValues,
            actionHistory: action_history,
            base64screenshot,
          });

          if (serverExecutionParams.tapInstantlyOnScreenCenter) {
            jsonResponse = getExecutionDataForInstantTapOnScreenCenter(
              serverExecutionParams.screenshotDimensions
            );
          } else if (foundCachedScreenshot !== undefined) {
            jsonResponse = getExecutionDataFromCache(
              foundCachedScreenshot,
              envVars,
              runInputValues
            );
          } else {
            jsonResponse = await getExecutionDataFromServer({
              buildId,
              base64screenshot,
              params: serverExecutionParams,
            });
          }
          appetizeCommandsToExecute = jsonResponse.appetizeCommands;
          if (envVars.length > 0) {
            appetizeCommandsToExecute = appetizeCommandsToExecute.map((value) =>
              value.replace(
                /{{(.*?)}}/g,
                (match, key) =>
                  replaceEnvVars(key.trim(), envVars, true) || value
              )
            );
          }
          gptSteps.current = [...gptSteps.current, jsonResponse.gptCommands];
          const currentTimestamp = Date.now();
          const timestampInMs =
            currentTimestamp - currentSessionStartedAt.current;

          if (settingsData.dynamicActionHistoryEnabled == true) {
            for (const gptCommand of jsonResponse.gptCommands) {
              const commandLowercase = gptCommand.toLowerCase();
              if (
                commandLowercase.includes("tabon:") &&
                commandLowercase.includes("continue")
              ) {
                console.log(" ==== FLUSHING ACTION HISTORY ==== ");
                gptSteps.current = [];
              }
            }
          }

          observer.next({
            type: "GPT_RESPONSE",
            data: {
              gptStep: jsonResponse.gptCommands,
              image: base64screenshot,
              actionHistory: action_history,
              screenContent: jsonResponse.ocrOutput,
              run,
              appetizeCommandsToExecute,
              serverExecutionParams,
              interactionLog: {
                screenshot: base64screenshot,
                gptCommands: jsonResponse.gptCommands,
                relativeTimestamp: timestampInMs,
                absoluteTimestamp: currentTimestamp,
                serverExecutionParams,
              },
            },
          });

          const gptCommandToDisplay = parseGptCommandsResponse(
            jsonResponse.gptCommands
          ).commands;
          const shouldIncreaseCurrentStep = await executeCommandsSequentially(
            observer,
            currentRunIndex,
            runs,
            appetizeCommandsToExecute,
            gptCommandToDisplay,
            serverExecutionParams.screenshotDimensions
          );

          if (shouldIncreaseCurrentStep && !observer.closed) {
            currentStep.current = currentStep.current + 1;
            await executeNextSteps(runs, currentRunIndex, observer, envVars);
          } else {
            currentStep.current = 0;
            currentCachedStep.current = 0;
            sameScreenshotsCounter.current = 0;
            repeatedStepsOccurred.current = false;
          }
        } catch (error) {
          console.error("error", error);
          const errorMessage = getErrorMessage(error);
          await emitLastInteractionLog(observer, errorMessage, run);

          if (currentRunIndex === runs.length - 1) {
            emitRunError(errorMessage, run, observer);
          } else {
            emitRunError(errorMessage, run, observer, parentRun);
          }
        }
      }
    },
    [buildData!.id, commandHandler, fromEditor, executeCommandsSequentially]
  );

  const createObservables = useCallback(
    ({
      runs,
      enableRetry,
    }: {
      runs: RunExecutionData[];
      enableRetry: boolean;
    }) => {
      retryCountRef.current = 0;
      const parsedEnvVars =
        settingsData.envVars?.map((envVar) => ({
          ...envVar,
          value: parsePrompt({
            prompt: envVar.value,
            envVars: [],
            runInputValues: [],
            currentTemplateTimestamp,
          }),
        })) ?? [];

      const steps$ = new Observable((observer) => {
        let unexpectedAppetizeErrorIntervalId: number;
        let executionTimeoutId: number;
        const runSteps = async () => {
          if (retryCountRef.current > 0) {
            currentStep.current = 0;
            currentCachedStep.current = 0;
            sameScreenshotsCounter.current = 0;
            croppedScreenshots.current = [];
            repeatedStepsOccurred.current = false;
            observer.next({
              type: "RUN_RETRY",
            });
            console.log("will end session on retry");
            await appetizeClient?.endSession();
            console.log("finished session on retry");
            const returnedPromise = await Timeout.wrap(
              appetizeClient?.startSession(),
              40000
            );
            console.log("started new session on retry", returnedPromise);
            if (appetizeClient.session == null) {
              await trackExceptionOnFirestore({
                error: "Could not initialize emulator session",
                source: "appetize",
              });
              observer.error({
                type: "RUN_COMPLETED",
                data: {
                  status: "blocked",
                  message: "Could not initialize emulator session",
                  run: runs[runs.length - 1],
                },
              });
            }
          }
          await delay(1000);
          if (currentSessionRef.current == null) {
            observer.next({
              type: "STEP_PROCESSING_TEXT_UPDATED",
              data: {
                text: "Starting session...",
              },
            });
            console.log(
              "will start session on STEP_PROCESSING_TEXT_UPDATED block"
            );
            await appetizeClient?.endSession();
            const returnedPromise = await Timeout.wrap(
              appetizeClient?.startSession(),
              40000
            );
            console.log(
              "session started on STEP_PROCESSING_TEXT_UPDATED block",
              returnedPromise
            );
            await delay(kEditorDelayAtSessionStartTime);
          }

          currentTemplateTimestamp.current = null;
          randomValuesTemplates.current = {};

          gptSteps.current = [];
          let currentRunIndex: null | number = null;

          executionTimeoutId = window.setTimeout(() => {
            handleAppetizeErrors({
              observer,
              currentRunIndex,
              runs,
              errorMessage: "timeout",
            });
          }, 1000 * 60 * 60 * runs.length);

          unexpectedAppetizeErrorIntervalId = window.setInterval(async () => {
            if (currentSessionRef.current?.data == null) {
              await handleAppetizeErrors({
                observer,
                currentRunIndex,
                runs,
                errorMessage: "The simulator session was disconnected",
              });
              setSession(null);
            }
          }, 60000);

          currentSessionRef.current?.data?.on("disconnect", async (_: any) => {
            await handleAppetizeErrors({
              observer,
              currentRunIndex,
              runs,
              errorMessage: "The simulator session was disconnected",
            });
            setSession(null);
          });

          currentSessionRef.current?.data?.on("error", (error) => {
            handleAppetizeErrors({
              observer,
              currentRunIndex,
              runs,
              errorMessage: getErrorMessage(error),
            });
          });

          for (const runIndex of runs.map((v, i) => i)) {
            croppedScreenshots.current = [];
            if (settingsData.shouldResetActionHistory) {
              gptSteps.current = [];
            } else {
              gptSteps.current = gptSteps.current.reduce((prev, current) => {
                // Check if 'current' is a string, if so, wrap it in an array
                const currentArray: string[] =
                  typeof current === "string" ? [current] : current;

                const rememberGptStep = currentArray.filter(
                  (gptStep: string) =>
                    gptStep.toLowerCase().includes("remember:") &&
                    !gptStep.startsWith("reasoning:")
                );

                if (rememberGptStep.length > 0) {
                  console.log("rememberGptStep", rememberGptStep);

                  const resetPrevSteps = rememberGptStep.map(
                    (rememberGptStep) =>
                      rememberGptStep.startsWith("[Step 0]")
                        ? rememberGptStep
                        : `[Step 0] ${rememberGptStep}`
                  );
                  return [...prev, resetPrevSteps];
                }
                return prev;
              }, []);
            }

            let base64screenshot: string | null = null;
            try {
              console.log("videoFrames.current", videoFrames.current?.length);
              await retryOperation(
                () => {
                  if (videoFrames.current.length === 0) {
                    throw new Error("No video frames available");
                  }
                  base64screenshot = uint8ArrayToBase64(
                    videoFrames.current[videoFrames.current.length - 1].buffer
                  );
                },
                2000,
                3
              );
            } catch (e) {}

            if (base64screenshot == null) {
              await handleAppetizeErrors({
                observer,
                currentRunIndex: runIndex,
                runs,
                errorMessage: "No video frames available",
              });
              break;
            }

            currentRunIndex = runIndex;
            const currentRun = runs[runIndex];
            const hasPreRequestsToMake =
              currentRun?.settings?.preRequests?.length > 0 &&
              currentRun?.disablePreRequests !== true;

            observer.next({
              type: "RUN_EXECUTION_STARTED",
              data: {
                runIndex,
                title: runs[runIndex].title,
                commands: getPromptStepsText(runs[runIndex].promptSteps),
                promptSteps: runs[runIndex].promptSteps,
                hasPreRequestsToMake,
                image: base64screenshot,
                testId: runs[runIndex].testId,
              },
            });
            if (currentRunIndex === 0 && !fromEditor) {
              await delay(kDelayAtSessionStartTime);
            }

            if (
              currentRun?.settings?.preRequests?.length > 0 &&
              currentRun?.disablePreRequests !== true
            ) {
              observer.next({
                type: "STEP_PROCESSING_TEXT_UPDATED",
                data: {
                  text: "Executing pre-request...",
                },
              });
              try {
                let runInputValues: InputValue[] = getInputValues({
                  currentRun: runs[runIndex],
                  nextRunToExecute: runs[runIndex + 1],
                });

                const { preRequestsGptSteps, preRequestsResponseValues } =
                  await getPreRequestsData(
                    currentRun,
                    parsedEnvVars,
                    runInputValues,
                    buildData?.organisationId!
                  );

                console.log({ preRequestsGptSteps, preRequestsResponseValues });

                const currentTimestamp = Date.now();
                const timestampInMs =
                  currentTimestamp - currentSessionStartedAt.current;

                observer.next({
                  type: "PRE_REQUESTS_RESPONSE",
                  data: {
                    image: base64screenshot,
                    preRequestsResponseValues,
                    interactionLogs:
                      preRequestsResponseValues.map<InteractionLog>(
                        (preRequestsResponseValue) => ({
                          screenshot: base64screenshot!,
                          gptCommands: [preRequestsResponseValue.curl],
                          preRequestResponse: preRequestsResponseValue,
                          relativeTimestamp: timestampInMs,
                          absoluteTimestamp: currentTimestamp,
                        })
                      ),
                  },
                });

                gptSteps.current = [
                  ...gptSteps.current,
                  ...preRequestsGptSteps,
                ];
              } catch (e) {
                console.error("Error in pre-request", e);
                try {
                  const error = e as AxiosError;
                  observer.next({
                    type: "PRE_REQUESTS_RESPONSE",
                    data: {
                      image: base64screenshot,
                      preRequestsResponseValues: [
                        {
                          curl: error.request.data.command,
                          status: error.response?.status,
                          data: error.message,
                        },
                      ],
                    },
                  });
                } catch (_) {}

                const parentRun =
                  runIndex === runs.length - 1 ? undefined : runs.at(-1);
                emitRunError(
                  "Pre-request failed",
                  currentRun,
                  observer,
                  parentRun
                );
                break;
              }
            }
            await executeNextSteps(runs, runIndex, observer, parsedEnvVars);
            if (observer.closed) {
              break;
            }
          }
          observer.complete();
        };

        runSteps();
        return () => {
          clearTimeout(executionTimeoutId);
          clearInterval(unexpectedAppetizeErrorIntervalId);
        };
      });

      const stop$ = new Subject();
      const stopExecutionHandler = () => {
        stop$.next(1);
        stop$.complete();
      };
      return {
        stopObservable: stopExecutionHandler,
        mainObservable: steps$.pipe(
          takeUntil(stop$),
          retry(enableRetry ? settingsData.numberOfRetriesOnFailure : 0)
        ),
      };
    },
    [
      executeNextSteps,
      settingsData.shouldResetActionHistory,
      settingsData.numberOfRetriesOnFailure,
      appetizeClient,
      fromEditor,
      buildData,
    ]
  );

  return { createObservables };
}
